@charset "UTF-8";
/* @import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&amp;display=swap); */
:root{
  --blr-sky:#00DBFF;
  --blr-dull-sky:#68A8DD;
  --blr-purple:#BF71DF;
  --blr-dull-pink:#C75CA2;
  --blr-pink:#FF00DC;
}

:root {
  --bs-sidebar-width: 260px;
  --bs-sidebar-width-sm: 80px;
  --bs-topbar-height: 70px;
  --bs-horizontal-menu-height: 55px;
  --bs-menu-transition: all 0.2s ease-in-out;
  --bs-menu-item-padding-x: 10px;
  --bs-menu-item-gap: 7px;
  --bs-menu-link-padding-x: 12px;
  --bs-menu-link-padding-y: 8px;
  --bs-menu-link-item-gap: 10px;
  --bs-menu-item-icon-size: 1.1rem;
  --bs-menu-item-font-size: 15px;
  --blr-skyblue-color: #00DBFF;
  --blr-dullblue-color: #68A8DD ;
  --blr-purple-color: #BF71DF ;
  --blr-dullpink-color: #C75CA2;
  --blr-pink-color: #FF00DC ;
  --blr-black-color: #000000  ;
  --blr-bg-gradient : linear-gradient(to right, rgba(0, 219, 255, 1), rgba(104, 168, 221, 1), rgba(191, 113, 223, 1),rgba(199, 92, 162, 1),rgba(255, 0, 220, 1));
}
a{
  text-decoration: none !important;
}
[data-bs-theme="dark"] {
  --bs-light: #424e5a;
  --bs-light-rgb: 66, 78, 90;
  --bs-dark: #f3f7f9;
  --bs-dark-rgb: 243, 247, 249;
  --bs-box-shadow: 0px 0px 35px 0px rgba(66, 72, 80, 0.15);
  --bs-box-shadow-sm: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  --bs-box-shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  --bs-box-shadow-inset: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.075);
}
[data-menu-color="light"] {
  --bs-menu-bg: #f5f7fa;
  --bs-menu-item-color: #6c757d;
  --bs-menu-item-hover-color: #346ee0;
  --bs-menu-item-active-color: #346ee0;
}
[data-bs-theme="dark"][data-menu-color="light"],
[data-menu-color="dark"] {
  --bs-menu-bg: #1f1f31;
  --bs-menu-item-color: #9097a7;
  --bs-menu-item-hover-color: #c8cddc;
  --bs-menu-item-active-color: #fff;
}
[data-menu-color="brand"] {
  --bs-menu-bg: rgb(12 74 110);
  --bs-menu-item-color: #cedce4;
  --bs-menu-item-hover-color: #ffffff;
  --bs-menu-item-active-color: #ffffff;
  --bs-menu-item-active-bg: rgba(255, 255, 255, 0.1);
}
[data-topbar-color="light"] {
  --bs-topbar-bg: #ffffff;
  --bs-topbar-item-color: #6c757d;
  --bs-topbar-item-hover-color: #346ee0;
}
[data-bs-theme="dark"][data-topbar-color="light"],
[data-topbar-color="dark"] {
  --bs-topbar-bg: #1f1f31;
  --bs-topbar-item-color: #8391a2;
  --bs-topbar-item-hover-color: #bccee4;
}
[data-topbar-color="brand"] {
  --bs-topbar-bg: #346ee0;
  --bs-topbar-item-color: rgba(255, 255, 255, 0.7);
  --bs-topbar-item-hover-color: #ffffff;
}
.form-button{
  background: var(--blr-bg-gradient);
  padding: 8px 20px;
  width: 100%;
  border-radius: 0.375rem;
  border: none !important;
}
.form-button:hover{
  color: #fff;
}
body.modal-open {
  overflow: hidden !important;  
}
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme="light"] {
  --bs-blue: #346ee0;
  --bs-indigo: #3f51b5;
  --bs-purple: #844aff;
  --bs-pink: #ff4bb2;
  --bs-red: #fa5944;
  --bs-orange: #fd7e14;
  --bs-yellow: #efb540;
  --bs-green: #2ac14e;
  --bs-teal: #20b799;
  --bs-cyan: #3cbade;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #98a6ad;
  --bs-gray-dark: #414d5f;
  --bs-gray-100: #f1f5f7;
  --bs-gray-200: #f7f7f7;
  --bs-gray-300: #e9edf3;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #98a6ad;
  --bs-gray-700: #6c757d;
  --bs-gray-800: #414d5f;
  --bs-gray-900: #323a46;
  --bs-primary: #346ee0;
  --bs-secondary: #6c757d;
  --bs-success: #20b799;
  --bs-info: #3cbade;
  --bs-warning: #efb540;
  --bs-danger: #fa5944;
  --bs-light: #e9edf3;
  --bs-dark: #323a46;
  --bs-pink: #ff4bb2;
  --bs-blue: #346ee0;
  --bs-primary-rgb: 52, 110, 224;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 32, 183, 153;
  --bs-info-rgb: 60, 186, 222;
  --bs-warning-rgb: 239, 181, 64;
  --bs-danger-rgb: 250, 89, 68;
  --bs-light-rgb: 233, 237, 243;
  --bs-dark-rgb: 50, 58, 70;
  --bs-pink-rgb: 255, 75, 178;
  --bs-blue-rgb: 52, 110, 224;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #e6dbff;
  --bs-secondary-bg-subtle: #f1f5f7;
  --bs-success-bg-subtle: #d4f3dc;
  --bs-info-bg-subtle: #d8f1f8;
  --bs-warning-bg-subtle: #fcf0d9;
  --bs-danger-bg-subtle: #fededa;
  --bs-light-bg-subtle: #f8fafb;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #ceb7ff;
  --bs-secondary-border-subtle: #f7f7f7;
  --bs-success-border-subtle: #aae6b8;
  --bs-info-border-subtle: #b1e3f2;
  --bs-warning-border-subtle: #f9e1b3;
  --bs-danger-border-subtle: #fdbdb4;
  --bs-light-border-subtle: #f7f7f7;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Nunito", cursive;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #8391a2 !important;
  --bs-body-color-rgb: 116, 122, 128;
  --bs-body-bg: #f9f7f2;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #747a80;
  --bs-secondary-color-rgb: 116, 122, 128;
  --bs-secondary-bg: #ffffff;
  --bs-secondary-bg-rgb: 255, 255, 255;
  --bs-tertiary-color: rgba(116, 122, 128, 0.5);
  --bs-tertiary-color-rgb: 116, 122, 128;
  --bs-tertiary-bg: #e9ecef;
  --bs-tertiary-bg-rgb: 233, 236, 239;
  --bs-link-color: #346ee0;
  --bs-link-color-rgb: 52, 110, 224;
  --bs-link-decoration: none;
  --bs-link-hover-color: #2c5ebe;
  --bs-link-hover-color-rgb: 44, 94, 190;
  --bs-code-color: #3cbade;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fcf0d9;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e9edf3;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  --bs-box-shadow-sm: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.12);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}
[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #8391a2 !important;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #1f1f31 !important;
  --bs-body-bg-rgb: 30, 30, 46;
  --bs-emphasis-color: #f1f5f7;
  --bs-emphasis-color-rgb: 241, 245, 247;
  --bs-secondary-color: #adb5bd;
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #1f1f31;
  --bs-secondary-bg-rgb: 31, 31, 49;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #3a4453;
  --bs-tertiary-bg-rgb: 58, 68, 83;
  --bs-primary-text-emphasis: #85a8ec;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #79d4c2;
  --bs-info-text-emphasis: #8ad6eb;
  --bs-warning-text-emphasis: #f5d38c;
  --bs-danger-text-emphasis: #fc9b8f;
  --bs-light-text-emphasis: #f1f5f7;
  --bs-dark-text-emphasis: #e9edf3;
  --bs-primary-bg-subtle: #1a0f33;
  --bs-secondary-bg-subtle: #323a46;
  --bs-success-bg-subtle: #082710;
  --bs-info-bg-subtle: #0c252c;
  --bs-warning-bg-subtle: #30240d;
  --bs-danger-bg-subtle: #32120e;
  --bs-light-bg-subtle: #414d5f;
  --bs-dark-bg-subtle: #212730;
  --bs-primary-border-subtle: #4f2c99;
  --bs-secondary-border-subtle: #6c757d;
  --bs-success-border-subtle: #19742f;
  --bs-info-border-subtle: #184a59;
  --bs-warning-border-subtle: #60481a;
  --bs-danger-border-subtle: #963529;
  --bs-light-border-subtle: #6c757d;
  --bs-dark-border-subtle: #414d5f;
  --bs-link-color: #b592ff;
  --bs-link-hover-color: #ceb7ff;
  --bs-link-color-rgb: 181, 146, 255;
  --bs-link-hover-color-rgb: 206, 183, 255;
  --bs-code-color: #ff93d1;
  --bs-highlight-color: #adb5bd;
  --bs-highlight-bg: #60481a;
  --bs-border-color: #303644;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #7fda95;
  --bs-form-valid-border-color: #7fda95;
  --bs-form-invalid-color: #fc9b8f;
  --bs-form-invalid-border-color: #fc9b8f;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.logo-box {
  background: linear-gradient(to right ,#810B07 , #091337);
  /* background-color: #ce3e41; */
}
.sixer-icon:focus {
  outline: none;
  box-shadow: none;
}
.side-nav-active{
  color: var(--ct-menu-item-hover) !important;
  text-decoration: none !;
}

.table>:not(caption)>*>*{
  color: var(--ct-table-color) !important;
}
.a:not([href]):not([class]), a:not([href]):not([class]):hover{
  /* color: var(--ct-menu-item-active) !important; */
}

.menu-arrow {
  transition: transform 0.3s !important;
}

.menu-arrow.rotate {
  transform: rotate(90deg) !important; 
}

/**  authentication background  gradient color*/
/* linear-gradient(90deg, rgba(237, 7, 8, 1) 0%, rgb(44 73 197) 53%, rgb(22 19 50) 100%) */

/* sidebar css */
.logo-box {
  top: 0;
  position: sticky;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
  height: var(--bs-topbar-height);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  padding: 0 var(--bs-menu-item-padding-x);
}
.logo-box .logo-sm {
  display: none;
}
.logo-box .logo-light {
  display: none;
}
.logo-box .logo-dark {
  display: block;
}
html[data-bs-theme="dark"] .main-menu .logo-box .logo-light,
html[data-menu-color="brand"] .main-menu .logo-box .logo-light,
html[data-menu-color="dark"] .main-menu .logo-box .logo-light {
  display: block;
}
html[data-bs-theme="dark"] .main-menu .logo-box .logo-dark,
html[data-menu-color="brand"] .main-menu .logo-box .logo-dark,
html[data-menu-color="dark"] .main-menu .logo-box .logo-dark {
  display: none;
}
html[data-bs-theme="dark"] #theme-mode .bx-moon:before {
  content: "\ec34";
}
html[data-bs-theme="dark"] .main-menu {
  border-right-style: dashed;
}

.main-menu {
  top: 0;
  position: fixed;
  z-index: 1000;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: var(--bs-sidebar-width) ;
  min-width: var(--bs-sidebar-width) ;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  background: #fff;
  -webkit-box-shadow: var(--bs-box-shadow);
  box-shadow: var(--bs-box-shadow); 
}
.main-menu [data-simplebar] {
  height: calc(100% - var(--bs-topbar-height));
}
.app-menu {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style-type: none;
  gap: var(--bs-menu-item-gap);
  padding-top: var(--bs-menu-item-gap);
}
.app-menu ul {
  padding-left: 0;
  list-style-type: none;
}
.app-menu > .menu-item {
  padding: 0 var(--bs-menu-item-padding-x);
  cursor: pointer !important;
}
.app-menu .menu-title {
  opacity: 0.6;
  cursor: default;
  font-size: calc(var(--bs-menu-item-font-size) * 0.75);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: gray;
  font-weight: 500;
  padding: var(--bs-menu-link-padding-y)
    calc(var(--bs-menu-link-padding-x) + var(--bs-menu-item-padding-x));
}
.app-menu .menu-item .menu-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  position: relative;
  color: #8391a2;
  gap: var(--bs-menu-link-item-gap);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out !important;
  border-radius: var(--bs-border-radius);
  font-size: var(--bs-menu-item-font-size);
  padding: var(--bs-menu-link-padding-y) var(--bs-menu-link-padding-x);
}
.app-menu .menu-item .menu-link.show,
.app-menu .menu-item .menu-link:focus,
.app-menu .menu-item .menu-link:hover,
.app-menu .menu-item .menu-link[aria-expanded="true"] {
  color: #710f11 !important;
  background-color: rgba(155, 155, 155,0.1);
}
.app-menu .menu-item .menu-link > .menu-icon {
  width: var(--bs-menu-item-icon-size);
  font-size: var(--bs-menu-item-icon-size);
  line-height: var(--bs-menu-item-icon-size);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.app-menu .menu-item .menu-link > .menu-icon svg {
  width: var(--bs-menu-item-icon-size);
  height: var(--bs-menu-item-icon-size);
}
.app-menu .menu-item .menu-link > .menu-icon + .menu-text {
  padding-left: 0;
}
.app-menu .menu-item .menu-link > .menu-text {
  white-space: nowrap;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-size: .9375rem !important;
  font-weight: 500;
  line-height: var(--bs-menu-item-font-size);
  padding-left: calc(
    var(--bs-menu-item-icon-size) + var(--bs-menu-link-item-gap)
  );
}
.app-menu .menu-item .menu-link > .menu-arrow {
  margin-left: auto;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  line-height: var(--bs-menu-item-font-size);
  font-size: calc(var(--bs-menu-item-font-size) * 1.18);
}
.app-menu .menu-item .menu-link > .menu-arrow:before {
  content: "\f0142";
  font-family: "Material Design Icons";
}
.app-menu .menu-item .menu-link[aria-expanded="true"] > .menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.app-menu .menu-item .menu-item > .menu-link {
  background-color: transparent;
}
.app-menu .menu-item .sub-menu {
  display: grid;
  gap: 6 123 px;
  margin-top: 6 123 px;
}
/* .app-menu .menu-item .sub-menu .menu-link::after {
  content: "";
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 4px;
  width: 4px;
  border-radius: 50px;  
  background-color: var(--bs-menu-item-color);
} */
.app-menu .menu-item .sub-menu .menu-text {
  font-size: calc(var(--bs-menu-item-font-size) * 0.95);
}
.app-menu .menu-item .sub-menu .sub-menu {
  padding-left: calc(var(--bs-menu-item-icon-size));
}
.app-menu .menu-item.active > .menu-link {
  color: #710f11 !important;
  background-color: var(--bs-menu-item-active-bg);
}
.app-menu .menu-item.active > .menu-link:not(.collapsed) > .menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.app-menu .menu-item.active .menu-item > .menu-link {
  background-color: transparent;
}
html[dir="rtl"] .menu-arrow::before {
  content: "\f0141" !important;
}
html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu {
  width: var(--bs-sidebar-width-sm);
  min-width: var(--bs-sidebar-width-sm);
  /* min-height: 1600px; */
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .simplebar-content-wrapper,
html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu .simplebar-mask {
  overflow: visible !important;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .simplebar-scrollbar {
  display: none !important;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .simplebar-offset {
  bottom: 0 !important;
}
html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu .logo-box {
  background: #838c9621;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .logo-box
  .logo-lg {
  display: none;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .logo-box
  .logo-sm {
  display: block;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-title {
  display: none;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item {
  position: relative;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link {
  -webkit-transition: none;
  transition: none;
  overflow: visible;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  .waves-ripple {
  -webkit-transform: none;
  transform: none;
  background: 0 0;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .badge,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .menu-arrow,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .menu-text {
  -webkit-transition: none;
  transition: none;
  display: none;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .menu-icon {
  text-align: center;
  -webkit-transition: none;
  transition: none;
  width: calc(
    var(--bs-sidebar-width-sm) - var(--bs-menu-item-padding-x) * 2 -
      var(--bs-menu-link-padding-x) * 2
  );
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .menu-text {
  padding-left: 0;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .menu-link
  > .menu-text::before {
  display: none;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item
  > .collapsing {
  display: none;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing {
  display: block;
  left: 100%;
  margin-top: -2px;
  position: absolute;
  height: inherit !important;
  -webkit-transition: none !important;
  transition: none !important;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu {
  background: var(--bs-body-bg);
  margin-left: 8px;
  -webkit-box-shadow: var(--bs-box-shadow-lg);
  box-shadow: var(--bs-box-shadow-lg);
  width: 200px;
  /* width: calc(var(--bs-sidebar-width) - var(--bs-sidebar-width-sm)); */
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding-bottom: var(--bs-menu-item-gap);
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  > .menu-item
  > .menu-link,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  > .menu-item
  > .menu-link {
  margin: 0 var(--bs-menu-item-gap);
  z-index: 5;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  > .menu-item
  > .menu-link
  > .menu-text,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  > .menu-item
  > .menu-link
  > .menu-text {
  display: block;
  /* color: var(--bs-body-color) !important; */
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  > .menu-item
  > .menu-link
  > .menu-arrow,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  > .menu-item
  > .menu-link
  > .menu-arrow {
  display: block;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  > .menu-item
  > .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  > .menu-item
  > .collapsing,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  > .menu-item
  > .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  > .menu-item
  > .collapsing {
  top: 0;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .menu-link,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .menu-link {
  color: var(--bs-body-color);
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .menu-link.show,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .menu-link:focus,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .menu-link:hover,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .menu-link[aria-expanded="true"],
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .menu-link.show,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .menu-link:focus,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .menu-link:hover,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .menu-link[aria-expanded="true"] {
  color: var(--bs-body-color) !important;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item.active
  > .menu-link,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item.active
  > .menu-link {
  color: #710f11 !important;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapse
  > .sub-menu
  .menu-item
  .collapsing,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .collapse,
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  .menu-item:hover
  > .collapsing
  > .sub-menu
  .menu-item
  .collapsing {
  top: 0;
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  > .menu-item:hover
  > .menu-link::after {
  content: "";
  inset: 0;
  position: absolute;
  width: var(--bs-sidebar-width);
}
html.sidebar-enable:not([data-sidebar-size="full"])
  .main-menu
  .app-menu
  > .menu-item:hover
  > .menu-link
  > .menu-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  margin: -1px;
  left: calc(var(--bs-sidebar-width-sm) - var(--bs-menu-item-padding-x) + 8px);
  background: rgb(217 217 217) !important;
  line-height: var(--bs-menu-item-icon-size);
  width: 200px !important;
  /* width: calc(var(--bs-sidebar-width) - var(--bs-sidebar-width-sm)); */
  padding: var(--bs-menu-link-padding-y) var(--bs-menu-link-padding-x);
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-box-shadow: var(--bs-box-shadow-lg);
  box-shadow: var(--bs-box-shadow-lg);
}
html[data-sidebar-size="full"] .main-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-left: calc(var(--bs-sidebar-width) * -1);
  border-color: transparent;
  opacity: 0;
}
html[data-sidebar-size="full"] .navbar-custom .logo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
html[data-sidebar-size="full"].sidebar-enable .main-menu {
  opacity: 1;
  z-index: 10565;
  margin-left: 0;
}
@media (min-width: 1200px) {
  html[data-layout="horizontal"] .layout-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  html[data-layout="horizontal"] .app-menu,
  html[data-layout="horizontal"] .container-fluid,
  html[data-layout="horizontal"] .container-lg,
  html[data-layout="horizontal"] .container-md,
  html[data-layout="horizontal"] .container-sm,
  html[data-layout="horizontal"] .container-xl,
  html[data-layout="horizontal"] .container-xxl,
  html[data-layout="horizontal"] .topbar {
    width: 85%;
    min-width: 85%;
    max-width: 85%;
    margin: 0 auto;
    padding: 0 0.75rem;
  }
  html[data-layout="horizontal"] .navbar-custom {
    margin-top: calc(var(--bs-topbar-height) * -1);
    margin-bottom: var(--bs-topbar-height);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  html[data-layout="horizontal"] .navbar-custom .topbar .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: 0 0;
  }
  html[data-layout="horizontal"] .navbar-custom .topbar .button-toggle-menu {
    display: none;
  }
  html[data-layout="horizontal"] .main-menu {
    display: block;
    height: var(--bs-horizontal-menu-height);
    min-height: var(--bs-horizontal-menu-height);
    max-height: var(--bs-horizontal-menu-height);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    z-index: 999;
    top: var(--bs-topbar-height);
    padding: 0;
    border-radius: 0;
    -webkit-box-shadow: var(--bs-box-shadow-sm);
    box-shadow: var(--bs-box-shadow-sm);
  }
  html[data-layout="horizontal"] .main-menu .logo-box {
    display: none;
  }
  html[data-layout="horizontal"] .main-menu .app-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    > .menu-item
    > .menu-link {
    height: var(--bs-horizontal-menu-height);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    > .menu-item:first-of-type
    > .menu-link {
    padding-left: 0;
  }
  html[data-layout="horizontal"] .main-menu .app-menu .menu-item {
    position: relative;
    padding: 0 var(--bs-menu-item-gap);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item
    .menu-link
    > .menu-text {
    padding-left: 0;
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item
    .menu-link
    .menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item:hover
    .menu-arrow {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item:hover
    > .collapse,
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item:hover
    > .collapsing {
    display: block !important;
    position: absolute;
    height: inherit !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  html[data-layout="horizontal"] .main-menu .app-menu .menu-item .sub-menu {
    padding: var(--bs-menu-item-gap) 0;
    gap: var(--bs-menu-item-gap);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item
    .sub-menu
    .menu-arrow {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  html[data-layout="horizontal"]
    .main-menu
    .app-menu
    .menu-item.active
    > .menu-link:not(.collapsed)
    > .menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  html[data-layout="horizontal"] .main-menu .collapse,
  html[data-layout="horizontal"] .main-menu .collapsing {
    width: 200px;
    display: none;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background-clip: padding-box;
    background: var(--bs-body-bg);
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    z-index: 1000;
    -webkit-box-shadow: var(--bs-box-shadow-lg);
    box-shadow: var(--bs-box-shadow-lg);
    border-radius: var(--bs-border-radius);
    border: 1px solid var(--bs-border-color);
  }
  html[data-layout="horizontal"] .main-menu .collapse .menu-item .menu-link,
  html[data-layout="horizontal"] .main-menu .collapsing .menu-item .menu-link {
    color: var(--bs-body-color) !important;
  }
  html[data-layout="horizontal"]
    .main-menu
    .collapse
    .menu-item
    .menu-link.show,
  html[data-layout="horizontal"]
    .main-menu
    .collapse
    .menu-item
    .menu-link:focus,
  html[data-layout="horizontal"]
    .main-menu
    .collapse
    .menu-item
    .menu-link:hover,
  html[data-layout="horizontal"]
    .main-menu
    .collapse
    .menu-item
    .menu-link[aria-expanded="true"],
  html[data-layout="horizontal"]
    .main-menu
    .collapsing
    .menu-item
    .menu-link.show,
  html[data-layout="horizontal"]
    .main-menu
    .collapsing
    .menu-item
    .menu-link:focus,
  html[data-layout="horizontal"]
    .main-menu
    .collapsing
    .menu-item
    .menu-link:hover,
  html[data-layout="horizontal"]
    .main-menu
    .collapsing
    .menu-item
    .menu-link[aria-expanded="true"] {
    color: var(--bs-dark);
    background-color: var(--bs-light);
  }
  html[data-layout="horizontal"]
    .main-menu
    .collapse
    .menu-item.active
    > .menu-link,
  html[data-layout="horizontal"]
    .main-menu
    .collapsing
    .menu-item.active
    > .menu-link {
    color: var(--bs-dark);
    background-color: var(--bs-light);
  }
  html[data-layout="horizontal"] .main-menu .collapse .menu-item .collapse,
  html[data-layout="horizontal"] .main-menu .collapse .menu-item .collapsing,
  html[data-layout="horizontal"] .main-menu .collapsing .menu-item .collapse,
  html[data-layout="horizontal"] .main-menu .collapsing .menu-item .collapsing {
    top: 0;
    left: 100%;
    right: auto;
  }
  html[data-layout="horizontal"] .menu-title {
    display: none;
  }
}
html[data-layout="horizontal"][data-sidebar-size="full"] .main-menu {
  overflow-y: auto;
}
html[data-layout="horizontal"][data-sidebar-size="full"] .main-menu .logo-box {
  background-color: var(--bs-menu-bg);
}
html.sidebar-enable:not([data-sidebar-size="full"]) 
.main-menu .app-menu .menu-item:hover > .collapse > 
.sub-menu .menu-item .menu-link.show, html.sidebar-enable:not([data-sidebar-size="full"]) 
.main-menu .app-menu .menu-item:hover > .collapse > .sub-menu .menu-item .menu-link:focus, 
html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu .app-menu .menu-item:hover > .collapse > 
.sub-menu .menu-item .menu-link:hover, html.sidebar-enable:not([data-sidebar-size="full"]) 
.main-menu .app-menu .menu-item:hover > .collapse > .sub-menu .menu-item .menu-link[aria-expanded="true"],
 html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu .app-menu .menu-item:hover > .collapsing > 
 .sub-menu .menu-item .menu-link.show, html.sidebar-enable:not([data-sidebar-size="full"]) 
 .main-menu .app-menu .menu-item:hover > .collapsing > .sub-menu .menu-item .menu-link:focus, 
 html.sidebar-enable:not([data-sidebar-size="full"]) .main-menu .app-menu .menu-item:hover > .collapsing > 
 .sub-menu .menu-item .menu-link:hover, html.sidebar-enable:not([data-sidebar-size="full"])
 .main-menu .app-menu .menu-item:hover > .collapsing > .sub-menu .menu-item .menu-link[aria-expanded="true"]{
  color: #710f11 !important;
} 

.leftside-menu *:focus{
  outline: none !important;
}

/* sidebar css end */



/* header css */
.authentication-bg {
  background-image: url(../public/assets/images/common/bg_sixer_login.svg);
  background-size: cover;
  background-position: center;
}
 .account-pages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh
}
.dropdown-admin{
  background-color: var(--ct-dropdown-bg);
  background-clip: padding-box;
  border: 1px solid var(--ct-dropdown-border-color);
  border-radius: .25rem;
  cursor: pointer;
}
/* header css end */



/* otp container */
.otp-container {
  margin: 0 50px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 767px) and (max-width: 992px) {
  .otp-container {
    margin: 20px 150px !important;
  }
}
@media (min-width: 0px) and (max-width: 484px) {
  .otp-container {
    margin: 0 20px;
  }
}
.otp__digit {
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.2rem;
  color: #000 !important;
}
._ok {
  color: green;
}
._notok {
  color: red;
}
/* otp container end */



/* pagination css */


.previous .bx, .next .bx{
  position: relative;
  top: 2px;
}
.pagination .previous a{
  text-decoration: none;
  color: gray;
}
.pagination .next a{
  text-decoration: none;
  color: gray;
}
.pagination .previous a:hover{
  color: white;
}
.pagination .previous a:hover{
  color: white;
}
.pagination .next a:hover{
  color: white;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  float: right;
  text-decoration: none;
  border-radius: 10px;
}
.pagination li {
  margin: 0 3px;
  cursor: pointer;
  color:gray;
  padding: 4px 13px;
  transition: all 0.2s ease-in;
  border-radius: 5px;
}
.pagination li:hover {
  color:white;
  background: #ce3e41;
  font-weight: bold;
}
.pagination li:hover.previous .bx, .pagination li:hover.next .bx{
  color: #fff;
}
.pagination-active-page {
  color: white !important;
  background-color: #ce3e41!important;
  font-weight: bold;
}
@media (max-width: 576px) {
  .pagination-container {
    flex-direction: column;
    align-items: center;
  }
  .pagination li {
    margin: 1.5px ;
    font-size: 12px;
  }
  .checkboxes__item{
    width: 100% !important;
  }
}
@media (max-width: 392px) {
  .pagination-container {
    flex-direction: column;
    align-items: center;
  }
  .pagination li {
    padding: 3px 6px;
    margin: 2px ;
    font-size: 14px;
  }
  .report-date{
    width: 40% !important;
  }
}

/* pagination css end */ 



/* responsive css */

@media (max-width: 1200px) {
  .profile-card-height {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 768px) {
  .reposts-search{
    text-align: start !important;
  }
}
@media (max-width: 680px) {
  .report-date{
    width: 50% ;
  }
}
@media (max-width: 400px) {
  .viewuser-table-fs{
    font-size: 11px;
  }
  .viewuser-table-fs tbody th {
    padding: 5px !important;
  }
  .viewuser-table-fs tbody td {
    padding: 5px !important;
  }
  .mdi-check-decagram {
    font-size: 10px;
  }
  .banner-edit-img img{
    width: 100px !important;
  }
}

/* responsive css end */




div.radio-with-Icon p.radioOption-Item .input-radio-active{ 
  margin: 0  !important;
  border-radius: 20px !important;
  border-color: #37871a !important;
}

.sixer-icon:focus {
  outline: medium none invert !important;
  outline: initial !important;
}

 




/* jodit text-editor */
.jodit-dialog_active_true, .jodit-dialog_modal_true .jodit-dialog__overlay{
  display: none !important;
}

.jodit-add-new-line span{
  display: none !important;
}

.jodit-workplace+.jodit-status-bar:not(:empty){
  display: none !important;
}
/* jodit text-editor end */



/* checkbox animation design */
/* .checkboxes__item{
  width: 25% !important;
} */
.checkbox.style-h {
    display: block !important ;
    position: relative !important ;
    cursor: pointer !important ;
    -webkit-user-select: none !important ;
    -moz-user-select: none !important ;
    -ms-user-select: none !important ;
    user-select: none !important ;
  }
  .checkbox.style-h input {
    position: absolute !important ;
    opacity: 0 !important ;
    cursor: pointer !important ;
    height: 0 !important ;
    width: 0 !important ;
  }
  .checkbox.style-h input:checked ~ .checkbox__checkmark {
    /* background-color: #f7cb15 !important ; */
  }
  .checkbox.style-h input:checked ~ .checkbox__checkmark:after {
    opacity: 1 !important ;
    top: -8px !important ;
  }
  .checkbox.style-h input:checked ~ .checkbox__body {
    background-position: 0 0 !important ;
    color: #000000 !important ;
  }
  .checkbox.style-h:hover input ~ .checkbox__checkmark {
    background-color: #50d8ff63 !important ;
  }
  .checkbox.style-h:hover input:checked ~ .checkbox__checkmark {
    background-color: #ce3e406b !important ;
  }
  .checkbox.style-h:hover .checkbox__body {
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2) !important ;
  }
  .checkbox.style-h .checkbox__checkmark {
    position: absolute !important ;
    top: 50% !important ;
    transform: translateY(-50%) !important ;
    left: 15px !important ;
    height: 22px !important ;
    width: 22px !important ;
    /* background-color: #f7cb15 !important ; */
    transition: background-color 0.25s ease !important ;
    border-radius: 4px !important ;
    box-shadow: inset 2px 2px 4px rgba(0,0,0,0.1), inset -2px -2px 4px rgba(255,255,255,0.4) !important ;
  }
  .checkbox.style-h .checkbox__checkmark:after {
    content: "" !important ;
    position: absolute !important ;
    left: 12px !important ;
    top: -15px !important ;
    width: 10px !important ;
    height: 26px !important ;
    border: solid #c52020 !important ;
    border-width: 0 4px 4px 0 !important ;
    transform: rotate(45deg) !important ;
    opacity: 0 !important ;
    transition: opacity 0.25s ease, top 0.25s ease !important ;
  }
  .checkbox.style-h .checkbox__body {
    color: #333 !important ;
    line-height: 1.4 !important ;
    /* font-size: 16px !important ; */
    transition: font-weight 0.25s ease !important ;
    font-weight: normal !important ; 
    background:linear-gradient(45deg, #ffb4b4 0%, #00bff640 50%, #50d8ff63 50%, #ffb4b4 100%) !important  ;
    background-size: 200% 100% !important ;
    background-position: 100% 0 !important ;
    padding: 15px !important ;
    /* padding-left: 52px !important ; */
    border-radius: 10px !important ;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2) !important ;
    transition: box-shadow 0.25s ease, background 1s 
    ease, color 0.25s ease !important ;
  }  
.scrollable-modal {
  max-height: 500px;
  overflow-y: auto;
  padding: 15px;
}

/* Fix header and footer */
.match-modal-header,
.match-modal-footer {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
}
.match-modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
}

/* dashboard card */
.fixed-height-card {
  height: 150px; 
}

.dynamic-height-card {
  height: auto;  
}

.dashboard-card {
  flex-grow: 1;
}

.dashboard-card td {
  padding: 14px !important;
}

.btn-sixer-bg-contest {
  background: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%) !important;
  color: #fff !important;
  border-radius: 7px;
  border: none !important;
  transition: background 0.8s ease-in-out, color 08s ease-in-out; /* Smooth transition */
}

.btn-sixer-bg-contest:hover {
  background: linear-gradient(160deg, #80D0C7 0%, #0093E9 100%) !important; /* Reverse gradient */ 
}
 
.btn-sixer-bg-launch {
  background: linear-gradient(45deg, #a7903d 0%, #be3434 100%);
  color: #ffffff !important;
  border-radius: 7px;
  border: none !important;
  transition: background 0.8s ease-in-out, color 08s ease-in-out; /* Smooth transition */
}

.btn-sixer-bg-launch:hover {
  background: linear-gradient(45deg, #be3434 0%, #a7903d 100%) !important; /* Reverse gradient */ 
}  

.btn-sixer-bg-squad {
  background: linear-gradient(45deg, #d3b751 0%, #db4db1 100%);
  color: #ffffff !important;
  border-radius: 7px;
  border: none !important;
  transition: background 0.8s ease-in-out, color 08s ease-in-out; /* Smooth transition */
}

.btn-sixer-bg-squad:hover {
  background: linear-gradient(45deg, #db4db1 0%, #d3b751 100%)  !important; /* Reverse gradient */ 
} 

.btn-sixer-bg-match {
  background:  linear-gradient(19deg, #3EECAC 0%, #EE74E1 100%);
  color: #ffffff !important;
  border-radius: 7px;
  border: none !important;
  transition: background 0.8s ease-in-out, color 08s ease-in-out; /* Smooth transition */
}

.btn-sixer-bg-match:hover {
  background:  linear-gradient(19deg, #EE74E1 0%, #3EECAC 100%) !important; /* Reverse gradient */ 
}
  



/* launch match badge icon */
.icon-button__badge1{
  position: absolute;
  top: -11px;
  right: 3px;
  width: 20px;
  height: 20px;
  background: #3688fc;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}
/* launch match badge icon end */

/* completed match badge icon */
.icon-button__badge2{
  position: absolute;
  top: -11px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #44badc;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}
/* completed match badge icon end */

/* live match badge icon */
.icon-button__badge3{
    position: absolute;
    top: -11px;
    right: -10px;
    width: 20px;
    height: 20px;
    background: #3688fc;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
/* live match badge icon end */

/* live series and completed series badge icon */
.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background: #cccccc;
}

.icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #ff9800;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}

.upcoming_icon-button__badge {
  position: absolute;
  top: -11px;
  right: 3px;
  width: 20px;
  height: 20px;
  background: #838c96;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}
/* live series and completed series badge icon end */

 
.left-important{
  left: 0px !important;
}


.sixer-bg-color{
  background: #810B07;
  /* background: linear-gradient(to right ,#810B07 , #091337); */
}





.custom-table th,
.custom-table td {
    border: 1px solid #ddd; /* Adds a border to all table cells */
}

.custom-table th + th,
.custom-table td + td {
    border-left: 1px solid #dbdbdb; /* Adds a thicker border between columns */
}

.bg-table{
  background-color: #f1f3fa;
}
.cron-li-text{
 color: rgb(187, 185, 185) !important;
}


.jodit-toolbar-button_delete{
  display: none !important;
}

.jodit-toolbar-button_addrow{
  display: none !important;
}

.jodit-toolbar-button_addcolumn{
  display: none !important;
}

.jodit-toolbar-button_speechRecognize{
  display: none !important;
}

/* //* pagination ('...')disable */
.disabled-break {
  pointer-events: none;
  color: rgb(189, 189, 189);
  cursor: default;
}
.break:hover {
  cursor: default !important; 
  color: inherit !important;  
  background-color: transparent !important;
} 